<template>
  <div class="m-5">
    <div class="row" v-if="yes===null">
      <div class="col">
        <h1>Are Whales Are Fish?</h1>
      </div>
    </div>
    <div class="row" v-if="yes===null">
      <div class="col">
        <button class="btn btn-success btn-lg m-4" @click="yes=true">Yes</button>
        <button class="btn btn-danger btn-lg" @click="yes=false">No</button>
      </div>
    </div>
    <div class="row" v-if="yes===true">
      <div class="col">
        <h1>Exactly.</h1>
      </div>
    </div>

    <div class="row" v-if="yes===false && fish===null">
      <div class="col">
        <h1>But where do they live?</h1>
      </div>
    </div>
    <div class="row" v-if="yes===false && fish===null">
      <div class="col">
        <button class="btn btn-primary btn-lg m-4" @click="fish=true">Underwater</button>
        <button class="btn btn-primary btn-lg" @click="fish=true">In the Ocean</button>
      </div>
    </div>

    <div class="row" v-if="fish">
      <div class="col">
        <h1>Where do fish live?</h1>
      </div>
    </div>
    <div class="row" v-if="fish">
      <div class="col">
        <button class="btn btn-primary btn-lg m-4" @click="yes = true, (fish=null)">Underwater</button>
        <button class="btn btn-primary btn-lg" @click="yes = true, (fish=null)">In the Ocean</button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Home',
  data: () => {
    return {
      yes: null,
      fish: null
    };
  },
  methods(){

  }
}
</script>

<style scoped>
</style>
